<template>
  <div>
    <a
      v-for="entry in languages"
      :key="entry.title"
      class="language-button"
      :style="languageStyle(entry.language)"
      @click="changeLocale(entry.language)"
    >
      <flag
        :iso="entry.flag"
        v-bind:squared="false"
        :title="entry.title"
        v-if="entry.title"
      />
    </a>
  </div>
</template>

<script>
import i18n from "@/plugins/language/i18n.js";

export default {
  data() {
    return {
      languages: [
        { flag:"ro", language: "ro", title: "Romana" },
        { flag:"gb", language: "en", title: "English" },
      ],
      currentLanguage : "ro"
    };
  },
  methods: {
      changeLocale(locale){
          i18n.locale = locale;
          this.currentLanguage = locale;
          this.$store.state.language = locale;
      },
      languageStyle(language){
          if (language === this.currentLanguage){
              return "opacity : 1";
          }
          return "";
      }
  },
  mounted(){
      i18n.locale = this.currentLanguage;
  }
};
</script>
<style scoped>
.language-button {
    padding: 5px;
    cursor: pointer;
    font-size: 22px;
    opacity: 0.5;
  }
  
  .language-div {
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    height: 30px;
  }
</style>