<template>
  <div class="wrapper" id="contact">
    <div class="inner">
      <!-- <div class="content d-flex justify-content-between align-items-center"> -->
      <div class="testimonials align-items-center">
        <section>
          <h2>{{ $t("contactTitle") }}</h2>
          <p v-html="$t('contactSubTitle')"></p>
        </section>
        <section>
          <!-- carrousel -->
          <iframe
            class="contact-map-medium"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1367.0906349298!2d23.45270737125977!3d46.74160789038296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4749110073e4a985%3A0x11303b3962e66ded!2sQuantum%20Racing!5e0!3m2!1sro!2sro!4v1705645223717!5m2!1sro!2sro"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue";

export default {
  data() {
    return {};
  },
  components: {
    "b-carousel": BCarousel,
    "b-carousel-slide": BCarouselSlide,
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.contact-map-medium {
  width: calc(100% - 2rem);
  min-height: 300px;
  height: 100%;
}
@media screen and (min-width: 980px) {
  .testimonials > :first-child {
    width: calc(50% - 2rem);
  }

  .testimonials > :last-child {
    width: calc(50% + 1rem);
  }
}

@media screen and (max-width: 480px) {
  .testimonials > :first-child {
    width: calc(100% - 1rem);
  }
}
</style>