export default[
    {
        name: "Croc Promotion",
        picture: "croc.svg",
        shortDesc: "Kart manufacturer",
        description: "Producator de karturi din Slovacia. CROC Promotion este partenerul tehnic al Quantum Racing.",
        url: "https://www.crocpromotion.sk/"
    },
    {
        name: "Flader & Span",
        picture: "flader.svg",
        shortDesc: "Kart manufacturer",
        description: "Facut la fix.",
        url: "https://www.instagram.com/fladersispan?igsh=bnA5Mmx6MmN4MTI%3D&utm_source=qr"
    },
    {
        name: "JRD Autotrust",
        picture: "jrd.svg",
        shortDesc: "Dealer masini SH",
        description: "Dealer auto. Partenerul traditional al Quantum Racing inca de la inceputul aventurii noastre.",
        url: "https://jrdautotrust.ro/"
    },
]