<template>
  <section class="wrapper" id="aboutUs">
    <div class="inner">
      <!-- <div class="content d-flex justify-content-between align-items-center"> -->
      <div class="testimonials">
        <section>
          <h2>{{ $t("aboutTitle") }}</h2>
          <p class="font-125rem" v-html="$t('aboutSubTitle')"></p>
          <br />
          <a href="#cta" class="button-red" v-html="$t('aboutButtonLabel')"></a>
        </section>

        <section>
          <!-- <img
            id="aboutOverlay"
            class="background-cover-no-repeat"
            :style="{
              'background-image': 'url(' + aboutusImageStyle + ') !important',
            }"
            width="100%"
            :src="require('@/assets/images/circuit_empty.svg')"
            alt=""
          /> -->
          <!-- carrousel -->
          <b-carousel class="w-100" id="carousel-1" :interval="4000" fade>
            <b-carousel-slide v-for="index in 10" :key="index">
              <template v-slot:img>
                <img
                  class="w-90"
                  :src="
                    require('@/assets/images/about-us/quantum-' +
                      index +
                      '.webp')
                  "
                  alt=""
                /> </template></b-carousel-slide
          ></b-carousel>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue";

export default {
  data() {
    return {
      // index: 1,
    };
  },
  components: {
    BCarousel,
    BCarouselSlide,
  },
  methods: {},
  computed: {
    aboutusImageStyle() {
      // return require("@/assets/images/aboutus/quantum-0" +
      //   this.index +
      //   ".jpg");
    },
  },
  mounted() {
    // setInterval(() => {
    //   this.index = this.index == 4 ? 1 : this.index + 1;
    // }, 2500);
    // $("#aboutusOverlay")
    //   .delay(500)
    //   .fadeTo(500, 0.3, function () {
    //     $("#aboutusOverlay").css(
    //       "background-image",
    //       "url(" +
    //         require("@/assets/images/about-us/quantum-0" +
    //           this.index +
    //           ".jpg") +
    //         ")"
    //     );
    //   })
    //   .fadeTo("slow", 1);
    // $(window).on("resize", function () {
    //   // if (window.visualViewport.width > 1280) {
    //   //   console.log("1280");
    //   // } else
    //   if (window.visualViewport.width > 960) {
    //     $(".overlay").css("top", -1 * $("#carousel-1").height() - 19);
    //   } else if (window.visualViewport.width > 780) {
    //     $(".overlay").css("top", -1 * $("#carousel-1").height());
    //   } else {
    //     $(".overlay").css("top", -1 * $("#carousel-1").height()) - 20;
    //   }
    // });
    // // this.$nextTick(function () {
    // if (window.visualViewport.width > 960) {
    //   console.log("980");
    //   $(".overlay").css("top", -1 * $("#carousel-1").height() - 19);
    // } else if (window.visualViewport.width > 780) {
    //   console.log("780");
    //   $(".overlay").css("top", -1 * $("#carousel-1").height());
    // } else {
    //   $(".overlay").css("top", -1 * $("#carousel-1").height()) - 20;
    // }
    // // $(".overlay").css("top", -1.1 * $("#carousel-1").height());
    // // $("#carousel-1").css("width", $(".overlay").width());
    // // });
    // this.$nextTick(function () {
    // if (window.visualViewport.width > 960) {
    //   console.log("980");
    //   $(".overlay").css("top", -1 * $("#carousel-1").height() - 19);
    // } else if (window.visualViewport.width > 780) {
    //   console.log("780");
    //   $(".overlay").css("top", -1 * $("#carousel-1").height());
    // } else {
    //   $(".overlay").css("top", -1 * $("#carousel-1").height()) - 20;
    // }
    // });
  },
};
</script>
<style scoped>
#aboutUs {
  background-color: #3b3b3b;
  color: #e5e5e5;
}

#aboutUs h2 {
  color: #e5e5e5;
  text-transform: uppercase;
  font-size: calc(1.75rem + 0.9vw);
  font-weight: bold;
}


@media screen and (min-width: 980px) {
  .testimonials > :first-child {
    width: calc(50% - 2rem);
  }

  .testimonials > :last-child {
    width: calc(50% + 1rem);
  }
}

@media screen and (max-width: 736px) {
  #aboutUs h2 {
    /* font-size: 2rem; */
  }
}

@media screen and (max-width: 480px) {
  #aboutus h2 {
    padding-top: 0px;
  }
  .testimonials > :first-child {
    width: calc(100% - 1rem);
  }
}
</style>