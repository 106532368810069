<template>
  <!-- 
  - menu mobile

 -->
  <div>
    <!-- Header -->
    <header id="header">
      <a class="logo" href="#">
        <img height="50" src="@/assets/images/logo.svg" alt="" />
      </a>
      <div class="menu-desktop">
        <div class="menu">
          <menu-items />
        </div>
      </div>
      <!-- // menu mobile -->
      <div class="d-flex">
        <!-- <a class="logo" href="#">
          <img height="50" src="@/assets/images/logo.svg" alt="" />
        </a> -->
        <div
          class="menu-mobile p-0 w-100"
          :class="{ 'open-menu': showMobileMenu }"
        >
          <div class="p-3 w-100 d-flex m-0 box-sizing-bb border-bottom">
            <div class="ml-auto close-menu" @click="openMenu(false)">
              <img src="@/assets/images/x.svg" />
            </div>
          </div>
          <div class="">
            <div class="menu-items">
              <a href="#aboutUs" @click="openMenu(false)">{{
                $t("menuAboutUs")
              }}</a>
              <a href="#cta" @click="openMenu(false)">{{ $t("menuTeam") }}</a>
              <a href="#school" @click="openMenu(false)">{{
                $t("menuSchool")
              }}</a>
              <a href="#news" @click="openMenu(false)">{{ $t("menuNews") }}</a>
              <a href="#sponsorship" @click="openMenu(false)">{{
                $t("menuSponsor")
              }}</a>
              <a href="#contact" @click="openMenu(false)">{{
                $t("menuContact")
              }}</a>
            </div>
          </div>
                
        </div>

        <!-- <div class="barTop"> -->
        <!-- <div class="logo-mobile" v-if="menuSelected.dashboard">
          <img height="50" src="@/assets/images/logo.svg" alt="" />
        </div> -->
        <div class="mobile-nav">
          <div class="menu-white" @click="openMenu(true)">
            <img src="@/assets/images/menu burger.svg" alt="" />
          </div>
        </div>
        <!-- <div class="user float-right bg-white ml-auto">
          <LanguageBar />
        </div> -->
      </div>
      <!-- </div> -->
      <!-- <nav><a href="#menu">Menu</a></nav> -->
    </header>
    <!-- Nav -->
    <!-- <nav id="menu">
      <ul class="links">
        <li><a href="index.html">Home</a></li>
        <li><a href="elements.html">Elements</a></li>
        <li><a href="generic.html">Generic</a></li>
      </ul>
    </nav> -->
    <!-- Banner -->
    <section id="banner">
      <div class="inner">
        <h1>{{ $t("teamName") }}</h1>
        <p v-html="$t('teamShortDesc')"></p>
      </div>
      <video
        autoplay
        loop
        muted
        playsinline
        src="@/assets/images/banner.mp4"
      ></video>
    </section>
    <!-- About Us -->
    <AboutUs />
    <!-- Team -->
    <Team />
    <!-- School -->
    <School />

    <News />
    <Sponsorship />
    <Contact />

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import LanguageBar from "../components/LanguageBar.vue";
import Team from "../components/Team.vue";
import School from "../components/School.vue";
import AboutUs from "../components/AboutUs.vue";
import Sponsorship from "../components/Sponsorship.vue";
import News from "../components/News.vue";
import Contact from "../components/Contact.vue";
import Footer from "../components/Footer.vue";
import MenuItems from "../components/MenuItems.vue";
import $ from "../assets/js/jquery.min.js";
import breakpoints from "../assets/js/breakpoints.min.js";
// import utils from '../assets/js/util.js'

export default {
  name: "App",
  props: {
    msg: String,
  },
  data() {
    return {
      // isMobile: document.body.clientWidth < 1004,
      showMobileMenu: false,
    };
  },
  components: {
    LanguageBar,
    MenuItems,
    Team,
    School,
    AboutUs,
    News,
    Sponsorship,
    Contact,
    Footer,
  },
  methods: {
    openMenu(open) {
      console.log("open menu");
      this.showMobileMenu = open;
    },
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 1004;
    },
  },
  mounted() {
    var $window = $(window),
      $banner = $("#banner"),
      $body = $("body");

    // Breakpoints.
    breakpoints({
      default: ["1681px", null],
      xlarge: ["1281px", "1680px"],
      large: ["981px", "1280px"],
      medium: ["737px", "980px"],
      small: ["481px", "736px"],
      xsmall: ["361px", "480px"],
      xxsmall: [null, "360px"],
    });

    // Play initial animations on page load.
    $window.on("load", function () {
      window.setTimeout(function () {
        $body.removeClass("is-preload");
      }, 100);
    });
  },
};
</script>
<style src="@/assets/css/main.css"></style>
<style scoped>
@media screen and (max-width: 1280px) {
  p {
    color: #fff;
  }
}
</style>

