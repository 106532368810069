<template>
  <div id="app">
    <router-view />
    <!-- <Quantum /> -->
  </div>
</template>

<script>
import Quantum from './views/Quantum.vue'


export default {
  name: 'App',
  components: {
    Quantum
  }
}
</script>
<style >
    /* @font-face {
    font-family: 'Eurostile';
    font-style: normal;
    font-weight: normal;
    src: local('Eurostile'), url('/assets/fonts/eurostile.woff') format('woff');
    }
    

    @font-face {
    font-family: 'EuroStyle Normal';
    font-style: normal;
    font-weight: normal;
    src: local('EuroStyle Normal'), url('/assets/fonts/EuroStyle Normal.woff') format('woff');
    } */
</style>
