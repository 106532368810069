<template>
  <div class="menu-items">
    <a href="#aboutUs">{{ $t("menuAboutUs") }}</a>
    <a href="#cta">{{ $t("menuTeam") }}</a>
    <a href="#school">{{ $t("menuSchool") }}</a>
    <a href="#news">{{ $t("menuNews") }}</a>
    <a href="#sponsorship">{{ $t("menuSponsor") }}</a>
    <a href="#contact">{{ $t("menuContact") }}</a>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue";

export default {
  data() {
    return {
      index: 1,
    };
  },
  components: {
    BCarousel,
    BCarouselSlide,
  },
  methods: {},
  computed: {
    aboutusImageStyle() {
      // return require("@/assets/images/aboutus/quantum-0" +
      //   this.index +
      //   ".jpg");
    },
  },
  mounted() {
    // setInterval(() => {
    //   this.index = this.index == 4 ? 1 : this.index + 1;
    // }, 2500);
    // $("#aboutusOverlay")
    //   .delay(500)
    //   .fadeTo(500, 0.3, function () {
    //     $("#aboutusOverlay").css(
    //       "background-image",
    //       "url(" +
    //         require("@/assets/images/about-us/quantum-0" +
    //           this.index +
    //           ".jpg") +
    //         ")"
    //     );
    //   })
    //   .fadeTo("slow", 1);
    // $(window).on("resize", function () {
    //   // if (window.visualViewport.width > 1280) {
    //   //   console.log("1280");
    //   // } else
    //   if (window.visualViewport.width > 960) {
    //     $(".overlay").css("top", -1 * $("#carousel-1").height() - 19);
    //   } else if (window.visualViewport.width > 780) {
    //     $(".overlay").css("top", -1 * $("#carousel-1").height());
    //   } else {
    //     $(".overlay").css("top", -1 * $("#carousel-1").height()) - 20;
    //   }
    // });
    // // this.$nextTick(function () {
    // if (window.visualViewport.width > 960) {
    //   console.log("980");
    //   $(".overlay").css("top", -1 * $("#carousel-1").height() - 19);
    // } else if (window.visualViewport.width > 780) {
    //   console.log("780");
    //   $(".overlay").css("top", -1 * $("#carousel-1").height());
    // } else {
    //   $(".overlay").css("top", -1 * $("#carousel-1").height()) - 20;
    // }
    // // $(".overlay").css("top", -1.1 * $("#carousel-1").height());
    // // $("#carousel-1").css("width", $(".overlay").width());
    // // });
    // this.$nextTick(function () {
    // if (window.visualViewport.width > 960) {
    //   console.log("980");
    //   $(".overlay").css("top", -1 * $("#carousel-1").height() - 19);
    // } else if (window.visualViewport.width > 780) {
    //   console.log("780");
    //   $(".overlay").css("top", -1 * $("#carousel-1").height());
    // } else {
    //   $(".overlay").css("top", -1 * $("#carousel-1").height()) - 20;
    // }
    // });
  },
};
</script>
<style scoped>
#aboutus {
  background-color: #3b3b3b;
  max-height: 100vh;
  color: #e5e5e5;
}

#aboutus h2 {
  color: #e5e5e5;
  text-transform: uppercase;
  font-size: 70px;
  font-weight: bold;
}

@media screen and (min-width: 980px) {
  .testimonials > :first-child {
    width: calc(50% - 2rem);
  }

  .testimonials > :last-child {
    width: calc(50% + 1rem);
  }
}

@media screen and (max-width: 1680px) {
  #aboutus h2 {
    font-size: 60px;
  }
}

@media screen and (max-width: 980px) {
  #aboutus h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 736px) {
  #aboutus h2 {
    font-size: 50px;
  }
}

@media screen and (max-width: 480px) {
  #aboutus h2 {
    padding-top: 0px;
    font-size: 30px;
  }
  .testimonials > :first-child {
    width: calc(100% - 1rem);
  }
}
</style>