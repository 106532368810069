<template>
  <section class="wrapper" id="news">
    <div class="inner">
      <header class="special">
        <h2>{{ $t("newsTitle") }}</h2>
        <p v-html="$t('newsSubTitle')"></p>
      </header>
      <div class="highlights">
        <section v-for="(entry, index) in news" :key="index">
          <div class="content d-flex flex-column justify-content-between">
            <a :href="entry.url" target="_blank">
              <h3>{{ entry.title }}</h3>
              <header>
                <!-- :style="{ 'background-image': 'url(${entry.pictureUrl})' }" -->

                <!-- <div> -->
                <!-- :style="{
                    'background-image':
                      'url(' + entry.pictureUrl + ') !important',
                  }" -->
                <img
                  class="imgBack"
                  :style="{
                    'background-image':
                      'url(' +
                      require('@/assets/images/news/' + entry.id + '.webp') +
                      ') !important',
                  }"
                  :src="require('@/assets/images/circuit_empty_light_grey.svg')"
                  alt=""
                />
                <!-- </div> -->
                <!-- <img class="w-100" :src="entry.pictureUrl" :alt="entry.title" /> -->
              </header>
              <!-- <h3>{{entry.name}}</h3> -->

              <!-- <p>
            </p> -->
              <small v-html="entry.shortDesc"></small>
            </a>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import news from "@/assets/js/data/news.js";
import $ from "../assets/js/jquery.min.js";

export default {
  data() {
    return {
      news: news,
    };
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.imgBack {
  background-color: #ffffff;
  /* background-image: url("/landscape1.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.highlights a,
.content {
  text-decoration: none;
  color: inherit;
  background-color: #e5e5e5 !important;
}

h3 {
  color: #175270;
  text-transform: uppercase;
  font-size: 35px;
  font-weight: bold;
  font-style: italic;
}

small {
  color: #000;
  font-size: 18px;
  font-style: italic;
  /* font-weight: bold; */
}

header.special p {
  font-size: calc(1.35rem);
  font-style: italic;
}

header.special h2 {
  font-size: calc(1.75rem + 0.9vw);
  font-weight: bold;
}

@media screen and (max-width: 736px) {
  header.special p {
    font-size: calc(1.2rem);
    font-style: italic;
  }

  header.special {
    text-align: center;
    margin-bottom: 1rem;
  }
}
</style>