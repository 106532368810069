export default[
    // descriere max 35 caractere pe rand
    {
        firstName: "Vladimir",
        lastName: "Dinu",
        shortDesc: "pilot - PROMO",
        description: "Explozie de energie."
    },
    {
        firstName: "Serban",
        lastName: "Cioc",
        shortDesc: "pilot - MICRO ROTAX",
        description: "Distractia pe primul loc."
    },
    {
        firstName: "Dragos",
        lastName: "Bexa",
        shortDesc: "pilot - MICRO ROTAX",
        description: "Determinarea in persoana."
    },
    {
        firstName: "Victor",
        lastName: "Roman",
        shortDesc: "pilot - KZ2",
        description: "Consecventa aduce rezultate."
    },
    {
        firstName: "Razvan",
        lastName: "Cioc",
        shortDesc: "pilot - KZ2 35+",
        description: "Pasiunea invinge. Intotdeauna."
    },
    {
        firstName: "Alex",
        lastName: "Teszler",
        shortDesc: "mechanical guru",
        description: "Sufletul echipei la pista."
    },
    {
        firstName: "Marian",
        lastName: "Cioc",
        shortDesc: "mecanic",
        description: "Cel mai dedicat mecanic din paddock."
    },
    {
        firstName: "Andreea",
        lastName: "Cioc",
        shortDesc: "operations manager",
        description: "Nu exista nu se poate!"
    } 
]