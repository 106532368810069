<template>
  <footer id="footer">
      <div class="inner">
        <div class="content">
          <section>
            <h3>{{ $t("acsQuantum") }}</h3>
            <p v-html='$t("acsQuantumDetails")'>
            </p>
          </section>
          <section>
            <h4>{{$t('siteLinks')}}</h4>
            <ul class="alt">
              <li><a href="#aboutUs">{{$t('menuAboutUs')}}</a></li>
              <li><a href="#cta">{{$t('menuTeam')}}</a></li>
              <li><a href="#school">{{$t('menuSchool')}}</a></li>
              <li><a href="#news">{{$t('menuNews')}}</a></li>
              <li><a href="#sponsorship">{{$t('menuSponsor')}}</a></li>
              <li><a href="#contact">{{$t('menuContact')}}</a></li>
            </ul>
          </section>
          <section>
            <h4>{{$t('socialsHeading')}}</h4>
            <ul class="plain">
              <li>
                <a class="socials" href="#">
                  <img
                    src="@/assets/images/socials/facebook.svg"
                    alt=""
                  />
                  &nbsp;Facebook
                </a>
              </li>
              <li>
                <a class="socials" href="#">
                  <img
                    src="@/assets/images/socials/tiktok.svg"
                    alt=""
                  />
                  &nbsp;TikTok
                </a>
              </li>
              <li>
                <a class="socials" href="#">
                  <img
                    src="@/assets/images/socials/instagram.svg"
                    alt=""
                  />
                  &nbsp;Instagram
                </a>
              </li>
              <li>
                <a class="socials" href="#">
                  <img
                    src="@/assets/images/socials/youtube.svg"
                    alt=""
                  />
                  &nbsp;YouTube
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </footer>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  methods: {},
  mounted() {},
};
</script>
