import Vue from 'vue'
import App from './App.vue'

import router from "./router";
import store from "./store";
import i18n from "@/plugins/language/i18n";
// import LayoutPlugin  from 'bootstrap-vue'
import BootstrapVue from 'bootstrap-vue'

import FlagIcon from "vue-flag-icon";

Vue.use(FlagIcon);
Vue.use(BootstrapVue);
Vue.config.productionTip = false

Vue.mixin({
  methods: {
    // viewport() {
    //   var e = window, a = 'inner';
    //   if (!('innerWidth' in window)) {
    //     a = 'client';
    //     e = document.documentElement || document.body;
    //   } 
    //   return { width: e[a + 'Width'], height: e[a + 'Height'] };
    // }
  }
}
);

new Vue({
  delimiters: ["[[", "]]"],
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')


import "@/assets/style.css";
import "/node_modules/bootstrap/dist/css/bootstrap.css";
import "/node_modules/bootstrap/dist/js/bootstrap.js";
import "@/assets/js/jquery.min.js";
import "@/assets/js/breakpoints.min.js";
// import "@/assets/js/util.js";
// import "@/assets/js/main.js";
