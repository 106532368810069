export default[
    // short desc max 83 caractere
    {
        id: 1,
        title: "Ne-am mutat in casa noua",
        shortDesc: "Suntem mandri ca avem un atelier nou in Floresti, Cluj. Cat de curand il vom inaugura, alaturi de prietenii nostri. Invitatia este deschisa tuturor. Revenim pentru detalii.",
        pictureUrl: "https://frk.com.ro/wp-content/uploads/2023/12/afis-la-multi-ani-300x187.jpeg",
        // url: "https://www.crocpromotion.sk/"
    },
    {
        id: 2,
        title: "Dragos Bexa se alatura QR",
        shortDesc: "In sezonul 2024 Dragos se alatura Quantum Racing. Suntem mandri sa avem alaturi de noi un obisnuit al podiumurilor la clasa Promo BB. Privim cu incredere catre noul sezon. <br /> Bun venit, Dragos!",
        pictureUrl: "https://frk.com.ro/wp-content/uploads/2023/12/afis-la-multi-ani-300x187.jpeg",
        //url: "https://www.crocpromotion.sk/"
    },
    {
        id: 3,
        title: "Dragos Bexa se alatura QR",
        shortDesc: "In sezonul 2024 Dragos se alatura Quantum Racing. Suntem mandri sa avem alaturi de noi un obisnuit al podiumurilor la clasa Promo BB. Privim cu incredere catre noul sezon. <br /> Bun venit, Dragos!",
        pictureUrl: "https://frk.com.ro/wp-content/uploads/2023/12/afis-la-multi-ani-300x187.jpeg",
        //url: "https://www.crocpromotion.sk/"
    }
]