export default [
    {
        servicesTitle: "Servicii",
        aboutTitle: "Despre noi",
        contactTitle: "Contact",

        teamName: "Quantum Racing",
        teamShortDesc: "Mici, dar sprinteni",

        // About section
        aboutTitle: "Despre noi",
        aboutSubTitle: "Quantum Racing este locul in care pasiunea pentru karting si performanta se intalnesc. Echipa noastra inseamna camaraderie, competitie si dedicare. Ne mandrim cu toate ingredientele care ne fac sa privim catre viitor cu ambitie si determinare.<br /> <br /> 'I have no idols. I admire work, dedication and competence.' <br /> - Ayrton Senna",
        aboutButtonLabel: "Cunoaste-ne",

        // Team section
        teamTitle: "Echipa QR",
        teamSubTitle: "Work hard. Play hard.",
        teamButtonLabel: "Alatura-te echipei",

        // School section
        schoolTitle: "Karting <br /> ca la karte",
        schoolSubTitle: "Scoala de Karting Quantum Racing<br /><br />Ne antrenam impreuna. Hranim pasiuni si intarim caractere. <br /><br /> Indiferent care iti sunt obiectivele, daca sunt ambitioase, te ajutam sa ti le aduci mai aproape.",
        schoolButtonLabel: "Hai la scoala!",

        // News section
        newsTitle: "Noutati",
        newsSubTitle: "Intotdeauna se intampla ceva in lumea Quantum Racing. Urmareste-ne aventurile!",

        // Sponsorship section
        sponsorTitle: "Parteneri",
        sponsorSubTitle: "Suntem mandri sa ii avem alaturi de noi",
        sponsorText: "Partenerii Quantum Racing iau parte activa la aventura noastra. Reprezinti o companie care impartaseste valorile noastre? Eforturile tale de marketing rezoneaza cu ideile si imaginea Quantum Racing? Ai o idee despre un proiect sau o poveste pe care vrei sa o impartasim? <br / > <br / > Te asteptam sa devenim parteneri.",
        sponsorButtonLabel: "Devin partener",

        // Contact section
        contactTitle: "Contact",
        contactSubTitle: "Vrei sa te alaturi echipei? Ai o idee despre un proiect in comun sau un parteneriat? Sau pur si simplu vrei sa ne saluti? <br /><br /> office@quantumracing.ro <br /> +40.723.406.649<br />+40.722.656.812<br /><br /> Fa-ne o vizita la atelier! Ne face placere sa iti aratam ce facem. Atentie! Pasiunea este molipsitoare!<br /><br />Urmareste-ne pe Social Media!",

        // socials
        socialsHeading: "Follow us",
        siteLinks: "Quick links",

        //footer
        acsQuantum: "A.C.S. Quantum Racing",
        acsQuantumDetails: "A.C.S. Quantum Racing este structura sportiva constituita in baza Legii nr. 69/2000 a educatiei fizice si sportului si detinatoare a Certificatului de Identitate Sportiva IF/A2/00260/2022, afiliata la FEDERATIA ROMANA DE KARTING.<br /> <br />Quantum Racing recunoaste autoritatea sportiva a FEDERATIEI ROMANE DE KARTING si, in mod obligatoriu accepta si respecta toate regulamentele, hotararile si dispozitiile acesteia.",

        //menu
        menuAboutUs: "Despre noi",
        menuTeam: "Echipa",
        menuSchool: "Scoala",
        menuNews: "Noutati",
        menuSponsor: "Parteneri",
        menuContact: "Contact"

    }
]
