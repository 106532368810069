<template>
  <section class="wrapper" id="sponsorship">
    <div class="inner">
      <header class="special">
        <h2>{{ $t("sponsorTitle") }}</h2>
        <p v-html="$t('sponsorSubTitle')"></p>
      </header>
      <div class="highlights">
        <section v-for="(member, index) in sponsors" :key="index">
          <div class="content d-flex flex-column justify-content-between">
            <header>
              <a :href="member.url" target="_blank"
                ><img
                  class="w-100"
                  :src="
                    require('@/assets/images/sponsorship/' + member.picture)
                  "
                  :alt="member.name"
                  @error="
                    $event.target.src = require('@/assets/images/team/Serban.png')
                  "
              /></a>
            </header>
            <!-- <h3>{{member.name}}</h3> -->
            <br />

            <p>
              {{ member.description }}
            </p>
          </div>
        </section>
      </div>
      <div>
        <!-- <header class="special">
        <h2>{{ $t("sponsorTitle") }}</h2>
        <p v-html="$t('sponsorSubTitle')"></p>
      </header> -->
        <p class="color-e5 text" v-html="$t('sponsorText')"></p>
        <br />
        <a
          href="#contact"
          class="button-red"
          v-html="$t('sponsorButtonLabel')"
        ></a>
        <br />
        <br />
      </div>
    </div>
  </section>
</template>

<script>
import sponsors from "@/assets/js/data/sponsorship.js";

export default {
  data() {
    return {
      sponsors: sponsors,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
#sponsorship {
  text-align: center !important;
  background-color: #3b3b3b;
}

header.special p {
  color: #e5e5e5 !important;
  font-size: calc(1.35rem);
  font-style: italic;
}

.text {
  color: #e5e5e5 !important;
  font-size: calc(1.1rem);
}

header.special h2 {
  color: #e5e5e5 !important;
  font-size: calc(1.75rem + 0.9vw);
  font-weight: bold;
}

.color-e5 {
  color: #e5e5e5 !important;
}

@media screen and (max-width: 736px) {
  header.special p {
    font-size: calc(1.2rem);
    font-style: italic;
  }

  header.special {
    text-align: center;
    margin-bottom: 1rem;
  }
}
</style>