import Vue from 'vue'
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import romanian from "@/plugins/language/ro.js";
import english from "@/plugins/language/en.js";

const messages = {
  ro: romanian[0],
  en: english[0]
}

const i18n = new VueI18n({
  locale: "ro",
  fallbackLocale: "en",
  messages
});

export default i18n;