<template>
  <!-- <section class="wrapper"> -->
  <section id="cta" class="wrapper">
    <div class="inner">
      <header class="special">
        <h2>{{ $t("teamTitle") }}</h2>
        <p v-html="$t('teamSubTitle')"></p>
      </header>
      <div class="testimonials">
        <section v-for="(member, index) in teamMembers" :key="index">
          <div class="content d-flex flex-column align-items-center">
            <div class="image">
              <img
                :src="
                  require('@/assets/images/team/' + member.firstName + '.png')
                "
                :alt="member.firstName + ' ' + member.lastName"
                @error="
                  $event.target.src = require('@/assets/images/team/Serban.png')
                "
              />
            </div>
            <blockquote>
              <p class="credit">
                <strong>{{ member.firstName }}</strong> &nbsp;
                <span>{{ member.shortDesc }}</span>
              </p>
              <p>
                {{ member.description }}
              </p>
            </blockquote>
          </div>
        </section>
      </div>
      <div>
        <a
          href="#contact"
          class="button-red"
          v-html="$t('teamButtonLabel')"
        ></a>
      </div>
    </div>
  </section>
  <!-- </section> -->
</template>

<script>
import team from "@/assets/js/data/team.js";

export default {
  data() {
    return {
      teamMembers: team,
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.inner {
  width: 75rem !important;
}

header.special p {
  color: #e5e5e5 !important;
  font-size: calc(1.35rem);
  font-style: italic;
}

header.special h2 {
  color: #e5e5e5 !important;
  font-size: calc(1.75rem + 0.9vw);
  font-weight: bold;
}

@media screen and (max-width: 1003px) {
  header.special p {
    color: #595959 !important;
  }
  header.special h2 {
    color: #595959 !important;
  }
}
</style>